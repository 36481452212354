/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const AccountActivityBox = () => (
  <div className="spacer">
    <div className="account-activity-box">
      <p>
        <a>account activity</a>
      </p>
    </div>
  </div>
);

export default AccountActivityBox;
