export const HEADER_TOPICS = [
  "art",
  "ask",
  "books",
  "domains",
  "food",
  "funny",
  "gaming",
  "gifs",
  "history",
  "memes",
  "movies",
  "music",
  "news",
  "pics",
  "politics",
  "programming",
  "religion",
  "quotes",
  "science",
  "space",
  "technology",
  "travel",
  "tv",
  "videos",
  "whatever"
]
