import React from "react";

const SidebarAd = () => (
  <div className="spacer">
    <div
      className="sponsorshipbox"
      style={{
        display: "none !important"
      }}
    />
  </div>
);

export default SidebarAd;

